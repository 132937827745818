export default (ctx, inject) => {
  // make lazy component loaders available for hydratableLoader
  // lets set all component options of components which were detected by nuxt/components including its loaders as prop of each component

  // now we are going to inject all the dynamic webpack imports to each component as single property with its own chunk
  const loaders = {
      LazyNuxtDynamic: () => import('../node_modules/@blokwise/dynamic/src/components/Dynamic.vue' /* webpackChunkName: "components/nuxt-dynamic" */).then(c => c.default || c),
      LazyBanner: () => import('../components/ds/materials/banner.vue' /* webpackChunkName: "components/banner" */).then(c => c.default || c),
      LazyBundleProducts: () => import('../components/ds/materials/bundleProducts.vue' /* webpackChunkName: "components/bundle-products" */).then(c => c.default || c),
      LazyButtons: () => import('../components/ds/materials/buttons.vue' /* webpackChunkName: "components/buttons" */).then(c => c.default || c),
      LazyCategoryProduct: () => import('../components/ds/materials/categoryProduct.vue' /* webpackChunkName: "components/category-product" */).then(c => c.default || c),
      LazyContents: () => import('../components/ds/materials/contents.vue' /* webpackChunkName: "components/contents" */).then(c => c.default || c),
      LazyEtcExbt: () => import('../components/ds/materials/etcExbt.vue' /* webpackChunkName: "components/etc-exbt" */).then(c => c.default || c),
      LazyExbt: () => import('../components/ds/materials/exbt.vue' /* webpackChunkName: "components/exbt" */).then(c => c.default || c),
      LazyHtml: () => import('../components/ds/materials/html.vue' /* webpackChunkName: "components/html" */).then(c => c.default || c),
      LazyHtmlProduct: () => import('../components/ds/materials/htmlProduct.vue' /* webpackChunkName: "components/html-product" */).then(c => c.default || c),
      LazyLink: () => import('../components/ds/materials/link.vue' /* webpackChunkName: "components/link" */).then(c => c.default || c),
      LazyNewProducts: () => import('../components/ds/materials/newProducts.vue' /* webpackChunkName: "components/new-products" */).then(c => c.default || c),
      LazyProduct: () => import('../components/ds/materials/product.vue' /* webpackChunkName: "components/product" */).then(c => c.default || c),
      LazyProductReview: () => import('../components/ds/materials/productReview.vue' /* webpackChunkName: "components/product-review" */).then(c => c.default || c),
      LazyTexts: () => import('../components/ds/materials/texts.vue' /* webpackChunkName: "components/texts" */).then(c => c.default || c),
      LazyTimer: () => import('../components/ds/materials/timer.vue' /* webpackChunkName: "components/timer" */).then(c => c.default || c),
      LazyCrosseditor: () => import('../components/Crosseditor.vue' /* webpackChunkName: "components/crosseditor" */).then(c => c.default || c),
      LazyCommonHeader: () => import('../components/common/Header.vue' /* webpackChunkName: "components/common-header" */).then(c => c.default || c),
      LazyCommonKakaoMap: () => import('../components/common/KakaoMap.vue' /* webpackChunkName: "components/common-kakao-map" */).then(c => c.default || c),
      LazyCommonLnbCmp: () => import('../components/common/LnbCmp.vue' /* webpackChunkName: "components/common-lnb-cmp" */).then(c => c.default || c),
      LazyCommonLoadingBar: () => import('../components/common/LoadingBar.vue' /* webpackChunkName: "components/common-loading-bar" */).then(c => c.default || c),
      LazyCommonPagination: () => import('../components/common/Pagination.vue' /* webpackChunkName: "components/common-pagination" */).then(c => c.default || c),
      LazyCommonProgressCmp: () => import('../components/common/ProgressCmp.vue' /* webpackChunkName: "components/common-progress-cmp" */).then(c => c.default || c),
      LazyCommonQuiEl: () => import('../components/common/QuiEl.vue' /* webpackChunkName: "components/common-qui-el" */).then(c => c.default || c),
      LazyCommonSkeletonBox: () => import('../components/common/SkeletonBox.vue' /* webpackChunkName: "components/common-skeleton-box" */).then(c => c.default || c),
      LazyCommonTopbaner: () => import('../components/common/Topbaner.vue' /* webpackChunkName: "components/common-topbaner" */).then(c => c.default || c),
      LazyCommonVideoProgressBar: () => import('../components/common/VideoProgressBar.vue' /* webpackChunkName: "components/common-video-progress-bar" */).then(c => c.default || c),
      LazyCommonFooter: () => import('../components/common/footer.vue' /* webpackChunkName: "components/common-footer" */).then(c => c.default || c),
      LazyCommonShareSns: () => import('../components/common/shareSns.vue' /* webpackChunkName: "components/common-share-sns" */).then(c => c.default || c),
      LazyCsImgPreview: () => import('../components/cs/ImgPreview.vue' /* webpackChunkName: "components/cs-img-preview" */).then(c => c.default || c),
      LazyCsOrderListCmp: () => import('../components/cs/OrderListCmp.vue' /* webpackChunkName: "components/cs-order-list-cmp" */).then(c => c.default || c),
      LazyCsProdUnitCmp: () => import('../components/cs/ProdUnitCmp.vue' /* webpackChunkName: "components/cs-prod-unit-cmp" */).then(c => c.default || c),
      LazyCsTabCmp: () => import('../components/cs/TabCmp.vue' /* webpackChunkName: "components/cs-tab-cmp" */).then(c => c.default || c),
      LazyEvBanrSwiperCmp: () => import('../components/ev/BanrSwiperCmp.vue' /* webpackChunkName: "components/ev-banr-swiper-cmp" */).then(c => c.default || c),
      LazyEvCpnRegist: () => import('../components/ev/EvCpnRegist.vue' /* webpackChunkName: "components/ev-cpn-regist" */).then(c => c.default || c),
      LazyEvCpnTabCmp: () => import('../components/ev/EvCpnTabCmp.vue' /* webpackChunkName: "components/ev-cpn-tab-cmp" */).then(c => c.default || c),
      LazyEvEvtCateCmp: () => import('../components/ev/EvtCateCmp.vue' /* webpackChunkName: "components/ev-evt-cate-cmp" */).then(c => c.default || c),
      LazyEvEvtSwiperCmp: () => import('../components/ev/EvtSwiperCmp.vue' /* webpackChunkName: "components/ev-evt-swiper-cmp" */).then(c => c.default || c),
      LazyEvEvtUnitCmp: () => import('../components/ev/EvtUnitCmp.vue' /* webpackChunkName: "components/ev-evt-unit-cmp" */).then(c => c.default || c),
      LazyFbDlvInfoForm: () => import('../components/fb/FbDlvInfoForm.vue' /* webpackChunkName: "components/fb-dlv-info-form" */).then(c => c.default || c),
      LazyFbFdrmPack: () => import('../components/fb/FbFdrmPack.vue' /* webpackChunkName: "components/fb-fdrm-pack" */).then(c => c.default || c),
      LazyFbPack: () => import('../components/fb/FbPack.vue' /* webpackChunkName: "components/fb-pack" */).then(c => c.default || c),
      LazyFbProdUnitCmp: () => import('../components/fb/FbProdUnitCmp.vue' /* webpackChunkName: "components/fb-prod-unit-cmp" */).then(c => c.default || c),
      LazyFbGnProdUnitCmp: () => import('../components/fb/GnProdUnitCmp.vue' /* webpackChunkName: "components/fb-gn-prod-unit-cmp" */).then(c => c.default || c),
      LazyFbTabCmp: () => import('../components/fb/TabCmp.vue' /* webpackChunkName: "components/fb-tab-cmp" */).then(c => c.default || c),
      LazyMbAccoCmp: () => import('../components/mb/AccoCmp.vue' /* webpackChunkName: "components/mb-acco-cmp" */).then(c => c.default || c),
      LazyMbNotiSwipe: () => import('../components/mb/NotiSwipe.vue' /* webpackChunkName: "components/mb-noti-swipe" */).then(c => c.default || c),
      LazyMbPvcSrcPop: () => import('../components/mb/PvcSrcPop.vue' /* webpackChunkName: "components/mb-pvc-src-pop" */).then(c => c.default || c),
      LazyMbSwiperCmp: () => import('../components/mb/SwiperCmp.vue' /* webpackChunkName: "components/mb-swiper-cmp" */).then(c => c.default || c),
      LazyMbTabCmp: () => import('../components/mb/TabCmp.vue' /* webpackChunkName: "components/mb-tab-cmp" */).then(c => c.default || c),
      LazyMbTitleCmp: () => import('../components/mb/TitleCmp.vue' /* webpackChunkName: "components/mb-title-cmp" */).then(c => c.default || c),
      LazyMsPointInfoCmp: () => import('../components/ms/PointInfoCmp.vue' /* webpackChunkName: "components/ms-point-info-cmp" */).then(c => c.default || c),
      LazyMsProdUnitCmp: () => import('../components/ms/ProdUnitCmp.vue' /* webpackChunkName: "components/ms-prod-unit-cmp" */).then(c => c.default || c),
      LazyMsTabCmp: () => import('../components/ms/TabCmp.vue' /* webpackChunkName: "components/ms-tab-cmp" */).then(c => c.default || c),
      LazyOrGnProdUnitCmp: () => import('../components/or/GnProdUnitCmp.vue' /* webpackChunkName: "components/or-gn-prod-unit-cmp" */).then(c => c.default || c),
      LazyOrProdUnitCmp: () => import('../components/or/OrProdUnitCmp.vue' /* webpackChunkName: "components/or-prod-unit-cmp" */).then(c => c.default || c),
      LazyOrProdUnitCmp2: () => import('../components/or/OrProdUnitCmp2.vue' /* webpackChunkName: "components/or-prod-unit-cmp2" */).then(c => c.default || c),
      LazyOrOrderClaim: () => import('../components/or/OrderClaim.vue' /* webpackChunkName: "components/or-order-claim" */).then(c => c.default || c),
      LazyOrOrderClaimTemp: () => import('../components/or/OrderClaimTemp.vue' /* webpackChunkName: "components/or-order-claim-temp" */).then(c => c.default || c),
      LazyPdImgPrevw: () => import('../components/pd/PdImgPrevw.vue' /* webpackChunkName: "components/pd-img-prevw" */).then(c => c.default || c),
      LazyPdZzimUnit: () => import('../components/pd/PdZzimUnit.vue' /* webpackChunkName: "components/pd-zzim-unit" */).then(c => c.default || c),
      LazyPubAccoCmp: () => import('../components/pub/AccoCmp.vue' /* webpackChunkName: "components/pub-acco-cmp" */).then(c => c.default || c),
      LazyPubAddressUnit: () => import('../components/pub/AddressUnit.vue' /* webpackChunkName: "components/pub-address-unit" */).then(c => c.default || c),
      LazyPubButtonEl: () => import('../components/pub/ButtonEl.vue' /* webpackChunkName: "components/pub-button-el" */).then(c => c.default || c),
      LazyPubCateList: () => import('../components/pub/CateList.vue' /* webpackChunkName: "components/pub-cate-list" */).then(c => c.default || c),
      LazyPubCategoryCmp: () => import('../components/pub/CategoryCmp.vue' /* webpackChunkName: "components/pub-category-cmp" */).then(c => c.default || c),
      LazyPubContQuick: () => import('../components/pub/ContQuick.vue' /* webpackChunkName: "components/pub-cont-quick" */).then(c => c.default || c),
      LazyPubContTool: () => import('../components/pub/ContTool.vue' /* webpackChunkName: "components/pub-cont-tool" */).then(c => c.default || c),
      LazyPubCouponUnit: () => import('../components/pub/CouponUnit.vue' /* webpackChunkName: "components/pub-coupon-unit" */).then(c => c.default || c),
      LazyPubCsProduct: () => import('../components/pub/CsProduct.vue' /* webpackChunkName: "components/pub-cs-product" */).then(c => c.default || c),
      LazyPubDatePicker: () => import('../components/pub/DatePicker.vue' /* webpackChunkName: "components/pub-date-picker" */).then(c => c.default || c),
      LazyPubFooterCmp: () => import('../components/pub/FooterCmp.vue' /* webpackChunkName: "components/pub-footer-cmp" */).then(c => c.default || c),
      LazyPubGnbNav: () => import('../components/pub/GnbNav.vue' /* webpackChunkName: "components/pub-gnb-nav" */).then(c => c.default || c),
      LazyPubHeader: () => import('../components/pub/Header.vue' /* webpackChunkName: "components/pub-header" */).then(c => c.default || c),
      LazyPubImgPreview: () => import('../components/pub/ImgPreview.vue' /* webpackChunkName: "components/pub-img-preview" */).then(c => c.default || c),
      LazyPubLnbCmp: () => import('../components/pub/LnbCmp.vue' /* webpackChunkName: "components/pub-lnb-cmp" */).then(c => c.default || c),
      LazyPubMainSwiper: () => import('../components/pub/MainSwiper.vue' /* webpackChunkName: "components/pub-main-swiper" */).then(c => c.default || c),
      LazyPubMyHeader: () => import('../components/pub/MyHeader.vue' /* webpackChunkName: "components/pub-my-header" */).then(c => c.default || c),
      LazyPubMyOrderStatus: () => import('../components/pub/MyOrderStatus.vue' /* webpackChunkName: "components/pub-my-order-status" */).then(c => c.default || c),
      LazyPubNotiSwipe: () => import('../components/pub/NotiSwipe.vue' /* webpackChunkName: "components/pub-noti-swipe" */).then(c => c.default || c),
      LazyPubOrderListCmp: () => import('../components/pub/OrderListCmp.vue' /* webpackChunkName: "components/pub-order-list-cmp" */).then(c => c.default || c),
      LazyPubOrderSidebar: () => import('../components/pub/OrderSidebar.vue' /* webpackChunkName: "components/pub-order-sidebar" */).then(c => c.default || c),
      LazyPubPDFViewer: () => import('../components/pub/PDFViewer.vue' /* webpackChunkName: "components/pub-p-d-f-viewer" */).then(c => c.default || c),
      LazyPubPointInfoCmp: () => import('../components/pub/PointInfoCmp.vue' /* webpackChunkName: "components/pub-point-info-cmp" */).then(c => c.default || c),
      LazyPubProdDetailTool: () => import('../components/pub/ProdDetailTool.vue' /* webpackChunkName: "components/pub-prod-detail-tool" */).then(c => c.default || c),
      LazyPubProdFilter: () => import('../components/pub/ProdFilter.vue' /* webpackChunkName: "components/pub-prod-filter" */).then(c => c.default || c),
      LazyPubProdSwiperCmp: () => import('../components/pub/ProdSwiperCmp.vue' /* webpackChunkName: "components/pub-prod-swiper-cmp" */).then(c => c.default || c),
      LazyPubProdUnit: () => import('../components/pub/ProdUnit.vue' /* webpackChunkName: "components/pub-prod-unit" */).then(c => c.default || c),
      LazyPubProdUnitCmp: () => import('../components/pub/ProdUnitCmp.vue' /* webpackChunkName: "components/pub-prod-unit-cmp" */).then(c => c.default || c),
      LazyPubProdUnitCmp2: () => import('../components/pub/ProdUnitCmp2.vue' /* webpackChunkName: "components/pub-prod-unit-cmp2" */).then(c => c.default || c),
      LazyPubProdUnitType02: () => import('../components/pub/ProdUnitType02.vue' /* webpackChunkName: "components/pub-prod-unit-type02" */).then(c => c.default || c),
      LazyPubProgressCmp: () => import('../components/pub/ProgressCmp.vue' /* webpackChunkName: "components/pub-progress-cmp" */).then(c => c.default || c),
      LazyPubQuiEl: () => import('../components/pub/QuiEl.vue' /* webpackChunkName: "components/pub-qui-el" */).then(c => c.default || c),
      LazyPubSearchComm: () => import('../components/pub/SearchComm.vue' /* webpackChunkName: "components/pub-search-comm" */).then(c => c.default || c),
      LazyPubStoreUnit: () => import('../components/pub/StoreUnit.vue' /* webpackChunkName: "components/pub-store-unit" */).then(c => c.default || c),
      LazyPubSwiperCmp: () => import('../components/pub/SwiperCmp.vue' /* webpackChunkName: "components/pub-swiper-cmp" */).then(c => c.default || c),
      LazyPubTabCmp: () => import('../components/pub/TabCmp.vue' /* webpackChunkName: "components/pub-tab-cmp" */).then(c => c.default || c),
      LazyPubTabmenu: () => import('../components/pub/Tabmenu.vue' /* webpackChunkName: "components/pub-tabmenu" */).then(c => c.default || c),
      LazyPubTitleCmp: () => import('../components/pub/TitleCmp.vue' /* webpackChunkName: "components/pub-title-cmp" */).then(c => c.default || c),
      LazyPubTopbaner: () => import('../components/pub/Topbaner.vue' /* webpackChunkName: "components/pub-topbaner" */).then(c => c.default || c),
      LazyPubVCalendar: () => import('../components/pub/VCalendar.vue' /* webpackChunkName: "components/pub-v-calendar" */).then(c => c.default || c),
      LazyPubVideoCmp: () => import('../components/pub/VideoCmp.vue' /* webpackChunkName: "components/pub-video-cmp" */).then(c => c.default || c),
      LazyPubVideoItem: () => import('../components/pub/VideoItem.vue' /* webpackChunkName: "components/pub-video-item" */).then(c => c.default || c),
      LazyPubVideoPlayer: () => import('../components/pub/VideoPlayer.vue' /* webpackChunkName: "components/pub-video-player" */).then(c => c.default || c),
      LazyPubProductType01: () => import('../components/pub/productType01.vue' /* webpackChunkName: "components/pub-product-type01" */).then(c => c.default || c),
      LazyPubTabCategory: () => import('../components/pub/tabCategory.vue' /* webpackChunkName: "components/pub-tab-category" */).then(c => c.default || c),
      LazyUgContQuick: () => import('../components/ug/ContQuick.vue' /* webpackChunkName: "components/ug-cont-quick" */).then(c => c.default || c),
      LazyUgCtenUnitCmp: () => import('../components/ug/CtenUnitCmp.vue' /* webpackChunkName: "components/ug-cten-unit-cmp" */).then(c => c.default || c),
      LazyUgImgTagger: () => import('../components/ug/ImgTagger.vue' /* webpackChunkName: "components/ug-img-tagger" */).then(c => c.default || c),
      LazyUgImgTaggerDtl: () => import('../components/ug/ImgTaggerDtl.vue' /* webpackChunkName: "components/ug-img-tagger-dtl" */).then(c => c.default || c),
      LazyUgImgTaggerDtlTest: () => import('../components/ug/ImgTaggerDtlTest.vue' /* webpackChunkName: "components/ug-img-tagger-dtl-test" */).then(c => c.default || c),
      LazyUgImgTaggerTest: () => import('../components/ug/ImgTaggerTest.vue' /* webpackChunkName: "components/ug-img-tagger-test" */).then(c => c.default || c),
      LazyUgProdSwiperCmp: () => import('../components/ug/ProdSwiperCmp.vue' /* webpackChunkName: "components/ug-prod-swiper-cmp" */).then(c => c.default || c),
      LazyUgProdUnitCmp: () => import('../components/ug/ProdUnitCmp.vue' /* webpackChunkName: "components/ug-prod-unit-cmp" */).then(c => c.default || c),
      LazyUgShrtfProdTagger: () => import('../components/ug/ShrtfProdTagger.vue' /* webpackChunkName: "components/ug-shrtf-prod-tagger" */).then(c => c.default || c),
      LazyUgShrtfTagProdUnit: () => import('../components/ug/ShrtfTagProdUnit.vue' /* webpackChunkName: "components/ug-shrtf-tag-prod-unit" */).then(c => c.default || c),
      LazyUgSwiperCmp: () => import('../components/ug/SwiperCmp.vue' /* webpackChunkName: "components/ug-swiper-cmp" */).then(c => c.default || c),
      LazyUgTabCmp: () => import('../components/ug/TabCmp.vue' /* webpackChunkName: "components/ug-tab-cmp" */).then(c => c.default || c),
      LazyUgTagProdUnitCmp: () => import('../components/ug/TagProdUnitCmp.vue' /* webpackChunkName: "components/ug-tag-prod-unit-cmp" */).then(c => c.default || c),
      LazyUgShareSns: () => import('../components/ug/shareSns.vue' /* webpackChunkName: "components/ug-share-sns" */).then(c => c.default || c),
      LazyCommonBulkAddOrderAddress: () => import('../components/common/bulk/AddOrderAddress.vue' /* webpackChunkName: "components/common-bulk-add-order-address" */).then(c => c.default || c),
      LazyCommonBulkCheckOrderAvailability: () => import('../components/common/bulk/CheckOrderAvailability.vue' /* webpackChunkName: "components/common-bulk-check-order-availability" */).then(c => c.default || c),
      LazyCommonBulkSearchOrderAddress: () => import('../components/common/bulk/SearchOrderAddress.vue' /* webpackChunkName: "components/common-bulk-search-order-address" */).then(c => c.default || c),
      LazyCommonBulkStepDialog: () => import('../components/common/bulk/StepDialog.vue' /* webpackChunkName: "components/common-bulk-step-dialog" */).then(c => c.default || c),
      LazyCommonButton: () => import('../components/common/button/CommonButton.vue' /* webpackChunkName: "components/common-button" */).then(c => c.default || c),
      LazyCommonButtonIconButton: () => import('../components/common/button/IconButton.vue' /* webpackChunkName: "components/common-button-icon-button" */).then(c => c.default || c),
      LazyCommonButtonLinkText: () => import('../components/common/button/LinkText.vue' /* webpackChunkName: "components/common-button-link-text" */).then(c => c.default || c),
      LazyCommonCollapseAccordion: () => import('../components/common/collapse/Accordion.vue' /* webpackChunkName: "components/common-collapse-accordion" */).then(c => c.default || c),
      LazyCommonInputRadioGroup: () => import('../components/common/input/RadioGroup.vue' /* webpackChunkName: "components/common-input-radio-group" */).then(c => c.default || c),
      LazyCommonInputSelectNumberButton: () => import('../components/common/input/SelectNumberButton.vue' /* webpackChunkName: "components/common-input-select-number-button" */).then(c => c.default || c),
      LazyCommonModalDaisoDialog: () => import('../components/common/modal/DaisoDialog.vue' /* webpackChunkName: "components/common-modal-daiso-dialog" */).then(c => c.default || c),
      LazyCommonProductThumbOverlay: () => import('../components/common/product/ThumbOverlay.vue' /* webpackChunkName: "components/common-product-thumb-overlay" */).then(c => c.default || c),
      LazyCommonScrollInfiniteScroll: () => import('../components/common/scroll/InfiniteScroll.vue' /* webpackChunkName: "components/common-scroll-infinite-scroll" */).then(c => c.default || c),
      LazyCommonTabCmp: () => import('../components/common/tab/TabCmp.vue' /* webpackChunkName: "components/common-tab-cmp" */).then(c => c.default || c),
      LazyCommonTemplateInfoContainer: () => import('../components/common/template/InfoContainer.vue' /* webpackChunkName: "components/common-template-info-container" */).then(c => c.default || c),
      LazyCommonTemplateNoData: () => import('../components/common/template/NoData.vue' /* webpackChunkName: "components/common-template-no-data" */).then(c => c.default || c),
      LazyCommonTextIconText: () => import('../components/common/text/IconText.vue' /* webpackChunkName: "components/common-text-icon-text" */).then(c => c.default || c),
      LazyCommonTooltip: () => import('../components/common/tooltip/Tooltip.vue' /* webpackChunkName: "components/common-tooltip" */).then(c => c.default || c),
      LazyCommonTooltipBox: () => import('../components/common/tooltip/TooltipBox.vue' /* webpackChunkName: "components/common-tooltip-box" */).then(c => c.default || c),
      LazyCsOrOrderListCmp: () => import('../components/cs/or/OrderListCmp.vue' /* webpackChunkName: "components/cs-or-order-list-cmp" */).then(c => c.default || c),
      LazyCsOrProdUnitCmp: () => import('../components/cs/or/ProdUnitCmp.vue' /* webpackChunkName: "components/cs-or-prod-unit-cmp" */).then(c => c.default || c),
      LazyDsCommonAccount: () => import('../components/ds/common/Account.vue' /* webpackChunkName: "components/ds-common-account" */).then(c => c.default || c),
      LazyDsCommonCategory: () => import('../components/ds/common/Category.vue' /* webpackChunkName: "components/ds-common-category" */).then(c => c.default || c),
      LazyDsCommonExbtProdUnitCmp: () => import('../components/ds/common/ExbtProdUnitCmp.vue' /* webpackChunkName: "components/ds-common-exbt-prod-unit-cmp" */).then(c => c.default || c),
      LazyDsCommonHtmlProdUnitCmp: () => import('../components/ds/common/HtmlProdUnitCmp.vue' /* webpackChunkName: "components/ds-common-html-prod-unit-cmp" */).then(c => c.default || c),
      LazyDsCommonMainSwiper: () => import('../components/ds/common/MainSwiper.vue' /* webpackChunkName: "components/ds-common-main-swiper" */).then(c => c.default || c),
      LazyDsCommonMaterial: () => import('../components/ds/common/Material.vue' /* webpackChunkName: "components/ds-common-material" */).then(c => c.default || c),
      LazyDsCommonNewUnit: () => import('../components/ds/common/NewUnit.vue' /* webpackChunkName: "components/ds-common-new-unit" */).then(c => c.default || c),
      LazyDsCommonProdSwiperCmp: () => import('../components/ds/common/ProdSwiperCmp.vue' /* webpackChunkName: "components/ds-common-prod-swiper-cmp" */).then(c => c.default || c),
      LazyDsCommonProdUnitCmp: () => import('../components/ds/common/ProdUnitCmp.vue' /* webpackChunkName: "components/ds-common-prod-unit-cmp" */).then(c => c.default || c),
      LazyDsCommonShareSns: () => import('../components/ds/common/ShareSns.vue' /* webpackChunkName: "components/ds-common-share-sns" */).then(c => c.default || c),
      LazyDsCommonSwiperCmp: () => import('../components/ds/common/SwiperCmp.vue' /* webpackChunkName: "components/ds-common-swiper-cmp" */).then(c => c.default || c),
      LazyDsCommonTabCmp: () => import('../components/ds/common/TabCmp.vue' /* webpackChunkName: "components/ds-common-tab-cmp" */).then(c => c.default || c),
      LazyDsCommonTitleCmp: () => import('../components/ds/common/TitleCmp.vue' /* webpackChunkName: "components/ds-common-title-cmp" */).then(c => c.default || c),
      LazyDsCommonSpexhbtTabCmp: () => import('../components/ds/common/spexhbtTabCmp.vue' /* webpackChunkName: "components/ds-common-spexhbt-tab-cmp" */).then(c => c.default || c),
      LazyDsDiySCRDIY0001: () => import('../components/ds/diy/SCR_DIY_0001.vue' /* webpackChunkName: "components/ds-diy-s-c-r-d-i-y0001" */).then(c => c.default || c),
      LazyDsDsaSCRDSA0027: () => import('../components/ds/dsa/SCR_DSA_0027.vue' /* webpackChunkName: "components/ds-dsa-s-c-r-d-s-a0027" */).then(c => c.default || c),
      LazyDsDsaCtgr: () => import('../components/ds/dsa/ctgr.vue' /* webpackChunkName: "components/ds-dsa-ctgr" */).then(c => c.default || c),
      LazyDsDsaCtgrLayer: () => import('../components/ds/dsa/ctgrLayer.vue' /* webpackChunkName: "components/ds-dsa-ctgr-layer" */).then(c => c.default || c),
      LazyDsList: () => import('../components/ds/dsa/dsList.vue' /* webpackChunkName: "components/ds-list" */).then(c => c.default || c),
      LazyDsDsaExhList: () => import('../components/ds/dsa/exhList.vue' /* webpackChunkName: "components/ds-dsa-exh-list" */).then(c => c.default || c),
      LazyDsDsaRankCtgr: () => import('../components/ds/dsa/rankCtgr.vue' /* webpackChunkName: "components/ds-dsa-rank-ctgr" */).then(c => c.default || c),
      LazyDsDscSCRDSC0001: () => import('../components/ds/dsc/SCR_DSC_0001.vue' /* webpackChunkName: "components/ds-dsc-s-c-r-d-s-c0001" */).then(c => c.default || c),
      LazyDsDscTabCmp: () => import('../components/ds/dsc/TabCmp.vue' /* webpackChunkName: "components/ds-dsc-tab-cmp" */).then(c => c.default || c),
      LazyDsDseSCRDSE0005: () => import('../components/ds/dse/SCR_DSE_0005.vue' /* webpackChunkName: "components/ds-dse-s-c-r-d-s-e0005" */).then(c => c.default || c),
      LazyDsDslSCRDSL0005: () => import('../components/ds/dsl/SCR_DSL_0005.vue' /* webpackChunkName: "components/ds-dsl-s-c-r-d-s-l0005" */).then(c => c.default || c),
      LazyDsDsmCategory: () => import('../components/ds/dsm/Category.vue' /* webpackChunkName: "components/ds-dsm-category" */).then(c => c.default || c),
      LazyDsDsmSCRDSM0027: () => import('../components/ds/dsm/SCR_DSM_0027.vue' /* webpackChunkName: "components/ds-dsm-s-c-r-d-s-m0027" */).then(c => c.default || c),
      LazyDsDSNSwiper: () => import('../components/ds/dsn/DSN_Swiper.vue' /* webpackChunkName: "components/ds-d-s-n-swiper" */).then(c => c.default || c),
      LazyDsDsnSCRDSN0007: () => import('../components/ds/dsn/SCR_DSN_0007.vue' /* webpackChunkName: "components/ds-dsn-s-c-r-d-s-n0007" */).then(c => c.default || c),
      LazyDsDstBandBanner: () => import('../components/ds/dst/BandBanner.vue' /* webpackChunkName: "components/ds-dst-band-banner" */).then(c => c.default || c),
      LazyDsDstBundleProdUnitCmp: () => import('../components/ds/dst/BundleProdUnitCmp.vue' /* webpackChunkName: "components/ds-dst-bundle-prod-unit-cmp" */).then(c => c.default || c),
      LazyDsDstContentUnitCmp: () => import('../components/ds/dst/ContentUnitCmp.vue' /* webpackChunkName: "components/ds-dst-content-unit-cmp" */).then(c => c.default || c),
      LazyDsDstEventUnitCmp: () => import('../components/ds/dst/EventUnitCmp.vue' /* webpackChunkName: "components/ds-dst-event-unit-cmp" */).then(c => c.default || c),
      LazyDsDstPrmtUnitCmp: () => import('../components/ds/dst/PrmtUnitCmp.vue' /* webpackChunkName: "components/ds-dst-prmt-unit-cmp" */).then(c => c.default || c),
      LazyDsDstProdSwiperCmp: () => import('../components/ds/dst/ProdSwiperCmp.vue' /* webpackChunkName: "components/ds-dst-prod-swiper-cmp" */).then(c => c.default || c),
      LazyDsDstSCRDST0017: () => import('../components/ds/dst/SCR_DST_0017.vue' /* webpackChunkName: "components/ds-dst-s-c-r-d-s-t0017" */).then(c => c.default || c),
      LazyDsDstSCRDST0019: () => import('../components/ds/dst/SCR_DST_0019.vue' /* webpackChunkName: "components/ds-dst-s-c-r-d-s-t0019" */).then(c => c.default || c),
      LazyDsDstSCRDST0021: () => import('../components/ds/dst/SCR_DST_0021.vue' /* webpackChunkName: "components/ds-dst-s-c-r-d-s-t0021" */).then(c => c.default || c),
      LazyDsDstSCRDST0023: () => import('../components/ds/dst/SCR_DST_0023.vue' /* webpackChunkName: "components/ds-dst-s-c-r-d-s-t0023" */).then(c => c.default || c),
      LazyDsDstTabCmp: () => import('../components/ds/dst/TabCmp.vue' /* webpackChunkName: "components/ds-dst-tab-cmp" */).then(c => c.default || c),
      LazyDsSkeletonUnitDiySkeleton: () => import('../components/ds/skeletonUnit/DiySkeleton.vue' /* webpackChunkName: "components/ds-skeleton-unit-diy-skeleton" */).then(c => c.default || c),
      LazyDsSkeletonUnitExbtSkeleton: () => import('../components/ds/skeletonUnit/ExbtSkeleton.vue' /* webpackChunkName: "components/ds-skeleton-unit-exbt-skeleton" */).then(c => c.default || c),
      LazyDsSkeletonUnitMainSkeleton: () => import('../components/ds/skeletonUnit/MainSkeleton.vue' /* webpackChunkName: "components/ds-skeleton-unit-main-skeleton" */).then(c => c.default || c),
      LazyOrBpaBpOrderSidebar: () => import('../components/or/bpa/BpOrderSidebar.vue' /* webpackChunkName: "components/or-bpa-bp-order-sidebar" */).then(c => c.default || c),
      LazyOrBpaBpProdUnitCmp: () => import('../components/or/bpa/BpProdUnitCmp.vue' /* webpackChunkName: "components/or-bpa-bp-prod-unit-cmp" */).then(c => c.default || c),
      LazyOrCommonOrderListCmp: () => import('../components/or/common/OrderListCmp.vue' /* webpackChunkName: "components/or-common-order-list-cmp" */).then(c => c.default || c),
      LazyOrCommonProdUnitCmp: () => import('../components/or/common/ProdUnitCmp.vue' /* webpackChunkName: "components/or-common-prod-unit-cmp" */).then(c => c.default || c),
      LazyOrCommonProdUnitCmp2: () => import('../components/or/common/ProdUnitCmp2.vue' /* webpackChunkName: "components/or-common-prod-unit-cmp2" */).then(c => c.default || c),
      LazyOrCommonProgressCmp: () => import('../components/or/common/ProgressCmp.vue' /* webpackChunkName: "components/or-common-progress-cmp" */).then(c => c.default || c),
      LazyOrCommonTabCmp: () => import('../components/or/common/TabCmp.vue' /* webpackChunkName: "components/or-common-tab-cmp" */).then(c => c.default || c),
      LazyOrFormGfRegPop: () => import('../components/or/form/GfRegPop.vue' /* webpackChunkName: "components/or-form-gf-reg-pop" */).then(c => c.default || c),
      LazyOrAcmBnf: () => import('../components/or/form/OrAcmBnf.vue' /* webpackChunkName: "components/or-acm-bnf" */).then(c => c.default || c),
      LazyOrDlvForm: () => import('../components/or/form/OrDlvForm.vue' /* webpackChunkName: "components/or-dlv-form" */).then(c => c.default || c),
      LazyOrOrdAdrs: () => import('../components/or/form/OrOrdAdrs.vue' /* webpackChunkName: "components/or-ord-adrs" */).then(c => c.default || c),
      LazyOrPdForm: () => import('../components/or/form/OrPdForm.vue' /* webpackChunkName: "components/or-pd-form" */).then(c => c.default || c),
      LazyOrPkupStr: () => import('../components/or/form/OrPkupStr.vue' /* webpackChunkName: "components/or-pkup-str" */).then(c => c.default || c),
      LazyOrSidebar: () => import('../components/or/form/OrSidebar.vue' /* webpackChunkName: "components/or-sidebar" */).then(c => c.default || c),
      LazyOrTrmPpup: () => import('../components/or/form/OrTrmPpup.vue' /* webpackChunkName: "components/or-trm-ppup" */).then(c => c.default || c),
      LazyOrFormPyCpn: () => import('../components/or/form/PyCpn.vue' /* webpackChunkName: "components/or-form-py-cpn" */).then(c => c.default || c),
      LazyOrFormPyMth: () => import('../components/or/form/PyMth.vue' /* webpackChunkName: "components/or-form-py-mth" */).then(c => c.default || c),
      LazyOrFormPyPntAcmyGf: () => import('../components/or/form/PyPntAcmyGf.vue' /* webpackChunkName: "components/or-form-py-pnt-acmy-gf" */).then(c => c.default || c),
      LazyOrOraProdUnitCmp: () => import('../components/or/ora/ProdUnitCmp.vue' /* webpackChunkName: "components/or-ora-prod-unit-cmp" */).then(c => c.default || c),
      LazyOrOraPstCardOrderSideBar: () => import('../components/or/ora/PstCardOrderSideBar.vue' /* webpackChunkName: "components/or-ora-pst-card-order-side-bar" */).then(c => c.default || c),
      LazyOrOraPstCardTab: () => import('../components/or/ora/PstCardTab.vue' /* webpackChunkName: "components/or-ora-pst-card-tab" */).then(c => c.default || c),
      LazyOrOraSearchIdTab: () => import('../components/or/ora/SearchIdTab.vue' /* webpackChunkName: "components/or-ora-search-id-tab" */).then(c => c.default || c),
      LazyOrOrbCashBill: () => import('../components/or/orb/CashBill.vue' /* webpackChunkName: "components/or-orb-cash-bill" */).then(c => c.default || c),
      LazyOrOrbMyOrderStatus: () => import('../components/or/orb/MyOrderStatus.vue' /* webpackChunkName: "components/or-orb-my-order-status" */).then(c => c.default || c),
      LazyOrOrbOftenBuyProd: () => import('../components/or/orb/OftenBuyProd.vue' /* webpackChunkName: "components/or-orb-often-buy-prod" */).then(c => c.default || c),
      LazyOrDtlAcmBnf: () => import('../components/or/orb/OrDtlAcmBnf.vue' /* webpackChunkName: "components/or-dtl-acm-bnf" */).then(c => c.default || c),
      LazyOrDtlDlvInfo: () => import('../components/or/orb/OrDtlDlvInfo.vue' /* webpackChunkName: "components/or-dtl-dlv-info" */).then(c => c.default || c),
      LazyOrDtlLsPyAm: () => import('../components/or/orb/OrDtlLsPyAm.vue' /* webpackChunkName: "components/or-dtl-ls-py-am" */).then(c => c.default || c),
      LazyOrDtlProd: () => import('../components/or/orb/OrDtlProd.vue' /* webpackChunkName: "components/or-dtl-prod" */).then(c => c.default || c),
      LazyOrDtlPymt: () => import('../components/or/orb/OrDtlPymt.vue' /* webpackChunkName: "components/or-dtl-pymt" */).then(c => c.default || c),
      LazyOrRefundDtl: () => import('../components/or/orb/OrRefundDtl.vue' /* webpackChunkName: "components/or-refund-dtl" */).then(c => c.default || c),
      LazyOrOrbRcntOrPd: () => import('../components/or/orb/RcntOrPd.vue' /* webpackChunkName: "components/or-orb-rcnt-or-pd" */).then(c => c.default || c),
      LazyOrOrbTaxBill: () => import('../components/or/orb/TaxBill.vue' /* webpackChunkName: "components/or-orb-tax-bill" */).then(c => c.default || c),
      LazyOrOrcBanr: () => import('../components/or/orc/Banr.vue' /* webpackChunkName: "components/or-orc-banr" */).then(c => c.default || c),
      LazyOrOrcOftenBuyPd: () => import('../components/or/orc/OftenBuyPd.vue' /* webpackChunkName: "components/or-orc-often-buy-pd" */).then(c => c.default || c),
      LazyOrOrcRcmdPd: () => import('../components/or/orc/RcmdPd.vue' /* webpackChunkName: "components/or-orc-rcmd-pd" */).then(c => c.default || c),
      LazyOrOrcRcntSelPd: () => import('../components/or/orc/RcntSelPd.vue' /* webpackChunkName: "components/or-orc-rcnt-sel-pd" */).then(c => c.default || c),
      LazyOrOrcSpbkSidebar: () => import('../components/or/orc/SpbkSidebar.vue' /* webpackChunkName: "components/or-orc-spbk-sidebar" */).then(c => c.default || c),
      LazyOrOrcSwiperCmp: () => import('../components/or/orc/SwiperCmp.vue' /* webpackChunkName: "components/or-orc-swiper-cmp" */).then(c => c.default || c),
      LazyOrOrgAttachedFilePC: () => import('../components/or/org/AttachedFilePC.vue' /* webpackChunkName: "components/or-org-attached-file-p-c" */).then(c => c.default || c),
      LazyOrOrgClaimAddCharge: () => import('../components/or/org/ClaimAddCharge.vue' /* webpackChunkName: "components/or-org-claim-add-charge" */).then(c => c.default || c),
      LazyOrOrgClaimList: () => import('../components/or/org/ClaimList.vue' /* webpackChunkName: "components/or-org-claim-list" */).then(c => c.default || c),
      LazyOrOrgClaimPickupStoreInfo: () => import('../components/or/org/ClaimPickupStoreInfo.vue' /* webpackChunkName: "components/or-org-claim-pickup-store-info" */).then(c => c.default || c),
      LazyOrOrgClaimReason: () => import('../components/or/org/ClaimReason.vue' /* webpackChunkName: "components/or-org-claim-reason" */).then(c => c.default || c),
      LazyOrOrgClaimRefundInfo: () => import('../components/or/org/ClaimRefundInfo.vue' /* webpackChunkName: "components/or-org-claim-refund-info" */).then(c => c.default || c),
      LazyOrOrgClaimReturnPlace: () => import('../components/or/org/ClaimReturnPlace.vue' /* webpackChunkName: "components/or-org-claim-return-place" */).then(c => c.default || c),
      LazyOrOrgClaimStoreInfo: () => import('../components/or/org/ClaimStoreInfo.vue' /* webpackChunkName: "components/or-org-claim-store-info" */).then(c => c.default || c),
      LazyOrOrgDelvryRefundInfo: () => import('../components/or/org/DelvryRefundInfo.vue' /* webpackChunkName: "components/or-org-delvry-refund-info" */).then(c => c.default || c),
      LazyOrOrgOrderClaimCancelDetail: () => import('../components/or/org/OrderClaimCancelDetail.vue' /* webpackChunkName: "components/or-org-order-claim-cancel-detail" */).then(c => c.default || c),
      LazyOrOrgOrderClaimExchangeDetail: () => import('../components/or/org/OrderClaimExchangeDetail.vue' /* webpackChunkName: "components/or-org-order-claim-exchange-detail" */).then(c => c.default || c),
      LazyOrOrgOrderClaimReturnDetail: () => import('../components/or/org/OrderClaimReturnDetail.vue' /* webpackChunkName: "components/or-org-order-claim-return-detail" */).then(c => c.default || c),
      LazyOrOrgOrderNotExists: () => import('../components/or/org/OrderNotExists.vue' /* webpackChunkName: "components/or-org-order-not-exists" */).then(c => c.default || c),
      LazyOrOrgProdUnitCmp: () => import('../components/or/org/ProdUnitCmp.vue' /* webpackChunkName: "components/or-org-prod-unit-cmp" */).then(c => c.default || c),
      LazyOrOrgProdUnitCmpTemp: () => import('../components/or/org/ProdUnitCmpTemp.vue' /* webpackChunkName: "components/or-org-prod-unit-cmp-temp" */).then(c => c.default || c),
      LazyOrOrgRefundInfo: () => import('../components/or/org/RefundInfo.vue' /* webpackChunkName: "components/or-org-refund-info" */).then(c => c.default || c),
      LazyOrOrgRetrievalInfo: () => import('../components/or/org/RetrievalInfo.vue' /* webpackChunkName: "components/or-org-retrieval-info" */).then(c => c.default || c),
      LazyOrOrrSCRORR0008: () => import('../components/or/orr/SCR_ORR_0008.vue' /* webpackChunkName: "components/or-orr-s-c-r-o-r-r0008" */).then(c => c.default || c),
      LazyPdCommonBreadCrumb: () => import('../components/pd/common/BreadCrumb.vue' /* webpackChunkName: "components/pd-common-bread-crumb" */).then(c => c.default || c),
      LazyPdCommonOrderListCmp: () => import('../components/pd/common/OrderListCmp.vue' /* webpackChunkName: "components/pd-common-order-list-cmp" */).then(c => c.default || c),
      LazyPdInfoUnit: () => import('../components/pd/common/PdInfoUnit.vue' /* webpackChunkName: "components/pd-info-unit" */).then(c => c.default || c),
      LazyPdRevwCttsSwiper: () => import('../components/pd/common/PdRevwCttsSwiper.vue' /* webpackChunkName: "components/pd-revw-ctts-swiper" */).then(c => c.default || c),
      LazyPdRevwUnit: () => import('../components/pd/common/PdRevwUnit.vue' /* webpackChunkName: "components/pd-revw-unit" */).then(c => c.default || c),
      LazyPdSwiperCmp: () => import('../components/pd/common/PdSwiperCmp.vue' /* webpackChunkName: "components/pd-swiper-cmp" */).then(c => c.default || c),
      LazyPdUnitCmp: () => import('../components/pd/common/PdUnitCmp.vue' /* webpackChunkName: "components/pd-unit-cmp" */).then(c => c.default || c),
      LazyPdCommonSwiperCmp: () => import('../components/pd/common/SwiperCmp.vue' /* webpackChunkName: "components/pd-common-swiper-cmp" */).then(c => c.default || c),
      LazyPdLayerCpnDown: () => import('../components/pd/layer/LayerCpnDown.vue' /* webpackChunkName: "components/pd-layer-cpn-down" */).then(c => c.default || c),
      LazyPdLayerDlvInfo: () => import('../components/pd/layer/LayerDlvInfo.vue' /* webpackChunkName: "components/pd-layer-dlv-info" */).then(c => c.default || c),
      LazyPdLayerModRevw: () => import('../components/pd/layer/LayerModRevw.vue' /* webpackChunkName: "components/pd-layer-mod-revw" */).then(c => c.default || c),
      LazyPdLayerQnaWrt: () => import('../components/pd/layer/LayerQnaWrt.vue' /* webpackChunkName: "components/pd-layer-qna-wrt" */).then(c => c.default || c),
      LazyPdLayerRevwCttsClt: () => import('../components/pd/layer/LayerRevwCttsClt.vue' /* webpackChunkName: "components/pd-layer-revw-ctts-clt" */).then(c => c.default || c),
      LazyPdLayerRevwDtl: () => import('../components/pd/layer/LayerRevwDtl.vue' /* webpackChunkName: "components/pd-layer-revw-dtl" */).then(c => c.default || c),
      LazyPdLayerRevwStmt: () => import('../components/pd/layer/LayerRevwStmt.vue' /* webpackChunkName: "components/pd-layer-revw-stmt" */).then(c => c.default || c),
      LazyPdLayerRevwWrt: () => import('../components/pd/layer/LayerRevwWrt.vue' /* webpackChunkName: "components/pd-layer-revw-wrt" */).then(c => c.default || c),
      LazyPdPurchaseBanner: () => import('../components/pd/pdr/PdPurchaseBanner.vue' /* webpackChunkName: "components/pd-purchase-banner" */).then(c => c.default || c),
      LazyPdPdrSCRPDR000C1: () => import('../components/pd/pdr/SCR_PDR_000C_1.vue' /* webpackChunkName: "components/pd-pdr-s-c-r-p-d-r000-c1" */).then(c => c.default || c),
      LazyPdPdrSCRPDR000C2: () => import('../components/pd/pdr/SCR_PDR_000C_2.vue' /* webpackChunkName: "components/pd-pdr-s-c-r-p-d-r000-c2" */).then(c => c.default || c),
      LazyPdPdrSCRPDR000C3: () => import('../components/pd/pdr/SCR_PDR_000C_3.vue' /* webpackChunkName: "components/pd-pdr-s-c-r-p-d-r000-c3" */).then(c => c.default || c),
      LazyPdPdrSCRPDR000C4: () => import('../components/pd/pdr/SCR_PDR_000C_4.vue' /* webpackChunkName: "components/pd-pdr-s-c-r-p-d-r000-c4" */).then(c => c.default || c),
      LazyPdPdrSCRPDR000C5: () => import('../components/pd/pdr/SCR_PDR_000C_5.vue' /* webpackChunkName: "components/pd-pdr-s-c-r-p-d-r000-c5" */).then(c => c.default || c),
      LazyPdPdrSCRPDR000C6: () => import('../components/pd/pdr/SCR_PDR_000C_6.vue' /* webpackChunkName: "components/pd-pdr-s-c-r-p-d-r000-c6" */).then(c => c.default || c),
      LazyPdPdrSCRPDR000C7: () => import('../components/pd/pdr/SCR_PDR_000C_7.vue' /* webpackChunkName: "components/pd-pdr-s-c-r-p-d-r000-c7" */).then(c => c.default || c),
      LazyPdPdrSCRPDR000C8: () => import('../components/pd/pdr/SCR_PDR_000C_8.vue' /* webpackChunkName: "components/pd-pdr-s-c-r-p-d-r000-c8" */).then(c => c.default || c),
      LazyPdPdrSCRPDR000C9: () => import('../components/pd/pdr/SCR_PDR_000C_9.vue' /* webpackChunkName: "components/pd-pdr-s-c-r-p-d-r000-c9" */).then(c => c.default || c),
      LazyPdPdtSCRPDT0001T1: () => import('../components/pd/pdt/SCR_PDT_0001T1.vue' /* webpackChunkName: "components/pd-pdt-s-c-r-p-d-t0001-t1" */).then(c => c.default || c),
      LazyPdPdtSCRPDT0001T2: () => import('../components/pd/pdt/SCR_PDT_0001T2.vue' /* webpackChunkName: "components/pd-pdt-s-c-r-p-d-t0001-t2" */).then(c => c.default || c),
      LazyPdPdtSCRPDT0003T1: () => import('../components/pd/pdt/SCR_PDT_0003T1.vue' /* webpackChunkName: "components/pd-pdt-s-c-r-p-d-t0003-t1" */).then(c => c.default || c),
      LazyUgUgpCmpltConts: () => import('../components/ug/ugp/CmpltConts.vue' /* webpackChunkName: "components/ug-ugp-cmplt-conts" */).then(c => c.default || c),
      LazyUgUgpDiverQsn: () => import('../components/ug/ugp/DiverQsn.vue' /* webpackChunkName: "components/ug-ugp-diver-qsn" */).then(c => c.default || c),
      LazyUgUgpDiverQsnDtl: () => import('../components/ug/ugp/DiverQsnDtl.vue' /* webpackChunkName: "components/ug-ugp-diver-qsn-dtl" */).then(c => c.default || c),
      LazyUgUgpDiverQstRfr: () => import('../components/ug/ugp/DiverQstRfr.vue' /* webpackChunkName: "components/ug-ugp-diver-qst-rfr" */).then(c => c.default || c),
      LazyUgUgpDiverQuest: () => import('../components/ug/ugp/DiverQuest.vue' /* webpackChunkName: "components/ug-ugp-diver-quest" */).then(c => c.default || c),
      LazyUgUgpPersonalInfoArgee: () => import('../components/ug/ugp/PersonalInfoArgee.vue' /* webpackChunkName: "components/ug-ugp-personal-info-argee" */).then(c => c.default || c),
      LazyUgUgpScrapContent: () => import('../components/ug/ugp/ScrapContent.vue' /* webpackChunkName: "components/ug-ugp-scrap-content" */).then(c => c.default || c),
      LazyUgUgpScrapUnit: () => import('../components/ug/ugp/ScrapUnit.vue' /* webpackChunkName: "components/ug-ugp-scrap-unit" */).then(c => c.default || c),
      LazyUgUgpTabCmp: () => import('../components/ug/ugp/TabCmp.vue' /* webpackChunkName: "components/ug-ugp-tab-cmp" */).then(c => c.default || c),
      LazyUgUgpTempSaveConts: () => import('../components/ug/ugp/TempSaveConts.vue' /* webpackChunkName: "components/ug-ugp-temp-save-conts" */).then(c => c.default || c),
      LazyDsCommonExbtProd: () => import('../components/ds/common/exbt/ExbtProd.vue' /* webpackChunkName: "components/ds-common-exbt-prod" */).then(c => c.default || c),
      LazyDsCommonFilterProdFilter: () => import('../components/ds/common/filter/ProdFilter.vue' /* webpackChunkName: "components/ds-common-filter-prod-filter" */).then(c => c.default || c),
      LazyDsCommonFilterProdFilterBubbles: () => import('../components/ds/common/filter/ProdFilterBubbles.vue' /* webpackChunkName: "components/ds-common-filter-prod-filter-bubbles" */).then(c => c.default || c),
      LazyDsCommonFilterProdFilterHeader: () => import('../components/ds/common/filter/ProdFilterHeader.vue' /* webpackChunkName: "components/ds-common-filter-prod-filter-header" */).then(c => c.default || c),
      LazyDsCommonFilterProdFilterSection: () => import('../components/ds/common/filter/ProdFilterSection.vue' /* webpackChunkName: "components/ds-common-filter-prod-filter-section" */).then(c => c.default || c),
      LazyDsCommonFilterProdFilterSectionCategoryTree: () => import('../components/ds/common/filter/ProdFilterSectionCategoryTree.vue' /* webpackChunkName: "components/ds-common-filter-prod-filter-section-category-tree" */).then(c => c.default || c),
      LazyDsCommonFilterProdFilterSectionPropertyTree: () => import('../components/ds/common/filter/ProdFilterSectionPropertyTree.vue' /* webpackChunkName: "components/ds-common-filter-prod-filter-section-property-tree" */).then(c => c.default || c),
      LazyDsCommonFilterUX04037: () => import('../components/ds/common/filter/UX_04_037.vue' /* webpackChunkName: "components/ds-common-filter-u-x04037" */).then(c => c.default || c),
      LazyOrOrbForeignPromoteSection: () => import('../components/or/orb/foreign/PromoteSection.vue' /* webpackChunkName: "components/or-orb-foreign-promote-section" */).then(c => c.default || c),
      LazyOrOrgCmpClaimNoData: () => import('../components/or/org/cmp/ClaimNoData.vue' /* webpackChunkName: "components/or-org-cmp-claim-no-data" */).then(c => c.default || c),
      LazyOrOrgCmpDateSelectSub: () => import('../components/or/org/cmp/DateSelectSub.vue' /* webpackChunkName: "components/or-org-cmp-date-select-sub" */).then(c => c.default || c),
      LazyOrOrgCmpOrderListCmp: () => import('../components/or/org/cmp/OrderListCmp.vue' /* webpackChunkName: "components/or-org-cmp-order-list-cmp" */).then(c => c.default || c),
      LazyOrOrgCmpPeriodTabSub: () => import('../components/or/org/cmp/PeriodTabSub.vue' /* webpackChunkName: "components/or-org-cmp-period-tab-sub" */).then(c => c.default || c),
      LazyOrOrgCmpProdUnitCmp: () => import('../components/or/org/cmp/ProdUnitCmp.vue' /* webpackChunkName: "components/or-org-cmp-prod-unit-cmp" */).then(c => c.default || c),
      LazyOrOrgScriptDataClaimCommon: () => import('../components/or/org/scriptData/claimCommon.js' /* webpackChunkName: "components/or-org-script-data-claim-common" */).then(c => c.default || c),
      LazyPdCommonAddressBulkOrderStatus: () => import('../components/pd/common/address/BulkOrderStatus.vue' /* webpackChunkName: "components/pd-common-address-bulk-order-status" */).then(c => c.default || c),
      LazyPdCommonAddressDeliveryAddress: () => import('../components/pd/common/address/DeliveryAddress.vue' /* webpackChunkName: "components/pd-common-address-delivery-address" */).then(c => c.default || c),
      LazyPdPdmAlarmUserInfo: () => import('../components/pd/pdm/alarm/UserInfo.vue' /* webpackChunkName: "components/pd-pdm-alarm-user-info" */).then(c => c.default || c)
  }

  const prefixes = ["nuxt"]

  inject("nuxtDynamic", { loaders, prefixes });
};
