const middleware = {}

middleware['authroute'] = require('../middleware/authroute.js')
middleware['authroute'] = middleware['authroute'].default || middleware['authroute']

middleware['mobileRedirect'] = require('../middleware/mobileRedirect.js')
middleware['mobileRedirect'] = middleware['mobileRedirect'].default || middleware['mobileRedirect']

middleware['noSessionRoute'] = require('../middleware/noSessionRoute.js')
middleware['noSessionRoute'] = middleware['noSessionRoute'].default || middleware['noSessionRoute']

middleware['token'] = require('../middleware/token.js')
middleware['token'] = middleware['token'].default || middleware['token']

export default middleware
